import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  input
} from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { UserDTO } from '../../domain/user/user.model';

import { MatButtonModule } from '@angular/material/button';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dottnet-mail-confirm',
    templateUrl: 'mail-confirm.component.html',
    styleUrls: ['mail-confirm.component.scss'],
    imports: [MatButtonModule, TranslateModule]
})
export class MailConfirmComponent implements OnInit, OnDestroy {
	@Output() readonly sendMailEvent: EventEmitter<any> = new EventEmitter();
	@Output() readonly returnToDrClickEmitter: EventEmitter<boolean> = new EventEmitter();

	mailConfirmationText: string = '';

	readonly user$ = input<Observable<UserDTO>>(new Observable());
	readonly isComingFromDrClick = input<boolean>(false);

	userSub: Subscription;
	errorSub: Subscription;

	constructor(private ts: TranslateService, private changeDetector: ChangeDetectorRef) {}

	ngOnInit(): void {
		this.userSub = this.user$().subscribe((user) => {
			this.mailConfirmationText =
				user?.idAnagrafica === -1
					? // The token has expired
					  `${this.ts.instant('dottnet.confirmMail.text.expired')}`
					: // If the user is reaching the component after the signup process
					  `${this.ts.instant('dottnet.confirmMail.text')}`;

			// Needed. Without the page won't update its content
			this.changeDetector.detectChanges();
		});
	}

	ngOnDestroy(): void {
		if (this.userSub) this.userSub.unsubscribe();
	}

	sendMail(event: any) {
		this.sendMailEvent.emit();
	}

	returnToDrClick() {
		this.returnToDrClickEmitter.emit(true);
	}
}
